import React from 'react';

const Testimonials = () => (
  <section className="testimonials">
    <h2>Kundenbewertungen</h2>
    <p>"Bravo Umzüge hat meinen Umzug stressfrei gemacht!" - Maria S.</p>
    <p>"Super Service, super Team!" - Thomas W.</p>
  </section>
);

export default Testimonials;

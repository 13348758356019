import React from 'react';

const Footer = () => (
  <footer className="footer">
    <p>&copy; 2024 Bravo Umzüge & Entrümpelungen. Alle Rechte vorbehalten.</p>
    <p>Datenschutz | Impressum</p>
  </footer>
);

export default Footer;

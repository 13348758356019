import React from 'react';
import Header from '../Header'; // Korrigierter Importpfad
import Footer from '../Footer';
import Services from '../Services';
import Testimonials from '../Testimonials';

const Home = () => {
  return (
    <div>
      <Header /> {/* Nutzt die Header-Komponente */}
      <main>
        <section className="hero">
          <h1>Willkommen bei Bravo Umzüge & Entrümpelungen</h1>
          <p>Ihr zuverlässiger Partner für professionelle Umzüge und Entrümpelungen</p>
          <a href="/contact" className="cta-button">Kostenloses Angebot anfordern</a>
        </section>
        <Services />
        <Testimonials />
      </main>
      <Footer /> {/* Nutzt die Footer-Komponente */}
    </div>
  );
};

export default Home;

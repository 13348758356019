import React from 'react';

const About = () => {
  return (
    <div>
      <h1>Über uns</h1>
      <p>Willkommen bei Bravo Umzüge & Entrümpelungen, Ihrem zuverlässigen Partner für professionelle Umzüge und Entrümpelungen.</p>
    </div>
  );
};

export default About;

import React from 'react';

const Services = () => (
  <section className="services">
    <h2>Unsere Dienstleistungen</h2>
    <ul>
      <li>Umzugsservice: Privat- und Firmenumzüge</li>
      <li>Entrümpelungen: Keller, Dachböden, Garagen und mehr</li>
      <li>Möbelmontage und -demontage</li>
      <li>Verpackungsservice: Professionelles Verpacken und Schutz Ihrer Güter</li>
      <li>Lagerung: Kurzfristige und Langfristige Lagerlösungen</li>
      <li>Spezialtransporte: Klaviere, Kunstwerke, Antike Möbel</li>
    </ul>
  </section>
);

export default Services;
